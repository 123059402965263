const header = () => {
  const siteHeader = document.querySelector('.js-header');

  const closeHeader = () => siteHeader.classList.add("out");
  const showHeader = () => siteHeader.classList.remove("out");
  const addFilled = () => siteHeader.classList.add("header--filled");
  const removeFilled = () => siteHeader.classList.remove("header--filled");
  let scrollPos = 0;

  document.addEventListener("scroll", () => {
    if (window.scrollY > siteHeader.offsetHeight) {

      if (document.body.getBoundingClientRect().top > scrollPos) {
        showHeader();
      } else {
        closeHeader();
      }
    }

    if(window.pageYOffset > 50) {
      addFilled();
    } else {
      removeFilled();
    }

    scrollPos = document.body.getBoundingClientRect().top;
  });

  document.addEventListener('mousemove', (event) => {
    const posY = event.clientY;

    if (posY < siteHeader.offsetHeight) {
      showHeader();
    }
  })
}

export default header
