import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation]);
Swiper.use([Pagination]);

const slider = () => {
  return new Swiper('.swiper-container', {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      type: 'custom',
      renderCustom (swiper, current, total) {
        return `${current  }/${  total }`;
      }
    },
    loop: true,
    allowTouchMove: false,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });
}

export default slider;
