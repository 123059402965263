import { TimelineMax } from 'gsap';
import isTouchDevice from '../helpers/detectTouch'

const menu = () => {
  const tl = new TimelineMax();
  const { body } = document;
  const hamburger = document.getElementById('hamburger');
  const mobileNav = document.getElementById('mobile-navigation');
  const navListItems = mobileNav.querySelectorAll('.js-nav-item');
  const text = document.getElementById('mobile-navigation-text')
  const eventType = isTouchDevice() ? 'touchstart' : 'click';
  const staggerElements = [...navListItems, text];

  const closeMenu = () => {
    hamburger.classList.remove('is-active');
    hamburger.classList.add('is-disabled');
    mobileNav.classList.remove('is-opened');
    hamburger.classList.remove('is-disabled');
    body.classList.remove('is-overflow-hidden');

    tl.to(mobileNav, {x: '-100%', opacity: 0, duration: 0.3});
  };

  const openMenu = () => {
    hamburger.classList.add('is-active');
    body.classList.add('is-overflow-hidden');
    hamburger.classList.add('is-disabled');

    tl
      .to(mobileNav, {x: '0%', opacity: 1, duration: 0.3})
      .from(
        staggerElements,
        {
          x: -20,
          opacity: 0,
          stagger: 0.05,
        });

    mobileNav.classList.add('is-opened');
    hamburger.classList.remove('is-disabled');
  };

  hamburger.addEventListener(eventType, () => {
    const isMobileNavOpened = mobileNav.classList.contains('is-opened');

    if(isMobileNavOpened) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  navListItems.forEach((item) => {
    item.addEventListener('click', () => {
      closeMenu();
    })
  })
};

export default menu;
